<template>
  <iframe :src="reportLink" />
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ReportPage',

  props: {
    test: {
      type: String,
      default: '',
    },
  },

  computed: {
    ...mapGetters('app', {
      apiAddr: 'apiAddr',
    }),
    reportLink() {
      return `https://toledo-staging-${this.$route.meta.project}.wlabel.site/report?test=${this.test}`
    },
  },
}
</script>

<style scoped>
iframe {
  width: 100%;
  height: calc(100vh - 170px);
  border: 0;
}
</style>
